<template>
  <div id="about" class="container">
    <h1 class="title">About Us</h1>
    <div class="row" v-if="about_section_one">
      <div class="col-12">
        <div  id="banner" :style="{ 'background-image': 'url(' + base_url + '/' + about_section_one.image.data.attributes.url + ')' }" style="background-repeat: no-repeat; background-color: #848484; background-size: cover">
          <div class="overlay d-flex flex-column justify-content-center">
            <h2 class="text-uppercase" v-html="marked(about_section_one.title)">
            </h2>
            <p>
              {{ about_section_one.description }}
            </p>
          </div>
        </div><!-- /#banner -->
      </div><!-- /.col-12 -->
    </div>

    <div class="row mt-4">
      <div class="col-12 col-sm-4 text-center">
        <div class="d-flex flex-column justify-content-center"
             style="height: 600px; background: linear-gradient(to right bottom, rgb(132, 132, 132, 0.8), rgb(132, 132, 132, 0.8)), url('images/living-area2.png') no-repeat;">
          <p class="p-4 text-white">
            <span style="float: left; font-size: 2.0em">“</span> <br/>
            {{ about_section_two.description }}
            <br/> <span style="float: right; font-size: 2.0em">”</span>
          </p>
        </div>
      </div>
      <div class="col-12 col-sm-8">
        <div style="height: 600px;">
          <img style="object-fit: cover; width: 100%; height: 100%; object-position: top" class="img-fluid" src="/images/Group_Photo.jpg">
        </div>
      </div>
    </div>
    <div class="space"></div>
    <section id="values" class="row mt-5">
      <div class="col-12 text-start text-sm-end"><h1 class="title">Our Values</h1></div>
      <div class="row">
        <div class="col-12 col-md-4 value">
          <div class="text-center">
            <h2>{{ values.value_one.title }}</h2>
          </div>
          <p class="text-center">
            {{ values.value_one.description }}
          </p>
        </div>
        <div class="col-12 col-md-4 value">
          <div class="text-center">
            <h2>{{ values.value_two.title }}</h2>
          </div>
          <p class="text-center">
            {{ values.value_two.description }}
          </p>
        </div>
        <div class="col-12 col-md-4 value">
          <div class="text-center">
            <h2>{{ values.value_three.title }}</h2>
          </div>
          <p class="text-center">
            {{ values.value_three.description }}
          </p>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
//import abouts from '../Abouts.json'

import {marked} from "marked";

export default {
  name: 'About',
  methods: {marked},
  data () {
    return {
      about_section_one: '',
      about_section_two: '',
      values: [],
      base_url: process.env.VUE_APP_PUBLIC_STRAPI_API_URL,
    }
  },
  metaInfo: {
    title: 'About Us'
  },
  created() {
    /*
    this.abouts = abouts.abouts
    this.values = abouts.values
     */

    this.axios.get(this.$hostname + '/api/about?populate[about_section_one][populate]=*', {
      headers: {
        'Authorization': 'Bearer ' + this.$token
      }
    }).then(response => {
      // push to array
      this.about_section_one = response.data.data.attributes.about_section_one
    })

    this.axios.get(this.$hostname + '/api/about?populate[about_section_two][populate]=*', {
      headers: {
        'Authorization': 'Bearer ' + this.$token
      }
    }).then(response => {
      // push to array
      this.about_section_two = response.data.data.attributes.about_section_two
    })

    this.axios.get(this.$hostname + '/api/about?populate=*', {
      headers: {
        'Authorization': 'Bearer ' + this.$token
      }
    }).then(response => {
      // push to array
      this.values = response.data.data.attributes

      console.log (this.values)
    })
  },
  mounted() {

  }
}
</script>
